<template>
    <div>
        <div class="card">
            <Toolbar class="mb-4">
                <template #start>
                    <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                    <!--
                    <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
                    -->
                </template>

                <template #end>
                    <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 inline-block" />
                    <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                </template>
            </Toolbar>

            <DataTable ref="dt" :value="products" v-model:selection="selectedProducts" dataKey="id" 
                :paginator="true" :rows="10" :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
                <template #header>
                    <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
						<h5 class="mb-2 md:m-0 p-as-md-center">Insumos / Materia Prima</h5>
						<span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                        </span>
					</div>
                </template>
                <!--
                    <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                -->

                <Column field="name" header="Nombre" :sortable="true" style="min-width:12rem"></Column>

                <Column field="description" header="Descripción" :sortable="true" style="min-width:16rem"></Column>

                <Column field="price" header="Precio" :sortable="true" style="min-width:8rem">
                    <template #body="slotProps">
                        {{formatCurrency(slotProps.data.valor)}}
                    </template>
                </Column>

                <Column field="unidad" header="Unidad" :sortable="true" style="min-width:6rem">
                    <template #body="slotProps">
                        <span style="text-transform: uppercase">{{formatUnity(slotProps.data.unidad)}}</span>
                    </template>
                </Column>

                <Column field="quantity" header="Cant" :sortable="true" style="min-width:4rem">
                    <template #body="slotProps">
                        {{slotProps.data.quantity}}
                    </template>
                </Column>
                
                <Column field="quantity" header="Estado" :sortable="true" style="min-width:11rem">
                    <template #body="slotProps">
                        <!--
                        <span :class="'product-badge status-outofstock'">
                            SIN STOCK
                        </span>
                        -->
                        <span :class="'product-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">
                            {{ getStatusName(slotProps.data.status) }}
                        </span> 

                    </template>
                </Column>

                <Column :exportable="false" style="min-width:8rem">
                    <template #body="slotProps">
                        <Button icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" @click="addStock(slotProps.data)" />
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
                    </template>
                </Column>

            </DataTable>
        </div>

        <Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="Detalles del insumo" :modal="true" class="p-fluid">            
            <div class="field">
                <label for="name">Nombre</label>
                <InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
                <small class="p-error" v-if="submitted && !product.name">Nombre es requerido.</small>
            </div>
            <div class="field">
                <label for="description">Description</label>
                <Textarea id="description" v-model="product.description" required="true" rows="3" cols="20" />
            </div>

            <div class="field">
				<label for="inventoryStatus" class="mb-3">Estado</label>
				<Dropdown id="inventoryStatus" v-model="product.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
					<template #value="slotProps">
						<div v-if="slotProps.value && slotProps.value.value">
							<span :class="'product-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
						</div>
						<div v-else-if="slotProps.value && !slotProps.value.value">
							<span :class="'product-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
						</div>
						<span v-else>
							{{slotProps.placeholder}}
						</span>
					</template>
				</Dropdown>
			</div>

            <div class="field">
                <label class="mb-3">Unidad</label>
                <div class="formgrid grid">
                    <div class="field-radiobutton col-6">
                        <RadioButton id="category1" name="category" value="1" v-model="product.unidad" />
                        <label for="category1">Metros</label>
                    </div>
                    <div class="field-radiobutton col-6">
                        <RadioButton id="category2" name="category" value="2" v-model="product.unidad" />
                        <label for="category2">Litros</label>
                    </div>
                    <div class="field-radiobutton col-6">
                        <RadioButton id="category3" name="category" value="3" v-model="product.unidad" />
                        <label for="category3">Kilos</label>
                    </div>
                    <div class="field-radiobutton col-6">
                        <RadioButton id="category4" name="category" value="4" v-model="product.unidad" />
                        <label for="category4">M2</label>
                    </div>

                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col">
                    <label for="price">Valor</label>
                    <InputNumber id="price" v-model="product.valor" mode="currency" currency="USD" locale="en-US" />
                </div>
                <div class="field col">
                    <label for="quantity">Cantidad</label>
                    <InputNumber id="quantity" v-model="product.quantity" integeronly />
                </div>
            </div>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="product">Seguro quieres eliminar <b>{{product.name}}</b>?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
                <Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="product">Are you sure you want to delete the selected products?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
                <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
            </template>
        </Dialog>


	</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import ProductService from './../services/crud.service';
import CrudService from './../services/crud.service';

export default {
    data() {
        return {
          routeName: null,
          products: null,
          productDialog: false,
          deleteProductDialog: false,
          deleteProductsDialog: false,
          product: {},
          selectedProducts: null,
          filters: {},
          submitted: false,
          validationErrors: [],
        };
      },
      created() {
        // get route name
        this.routeName = this.$route.name;
        
        //this.initFilters();
      },
      mounted() {
        CrudService.getCRUD(this.routeName).then(
          (data) => (this.products = data)
        );
      },
    setup() {
        onMounted(() => {
            CrudService.getCRUD('insumos','insumo').then(data => products.value = data);
        })

        const toast = useToast();
        const dt = ref();
        const products = ref();
        const productDialog = ref(false);
        const deleteProductDialog = ref(false);
        const deleteProductsDialog = ref(false);
        const product = ref({});
        //const productService = ref(new ProductService());
        const selectedProducts = ref();
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });
        const submitted = ref(false);
        const statuses = ref([
	     	{label: 'EN STOCK', value: 'instock'},
	     	{label: 'BAJO STOCK', value: 'lowstock'},
	     	{label: 'SIN STOCK', value: 'outofstock'}
        ]);

        const estados = ref({
            'INSTOCK': "EN STOCK",
            'LOWSTOCK': "BAJO STOCK",
            'OUTOFSTOCK': "SIN STOCK"
        });

        
        const formatUnity = (value) => {
            
           switch(value) {
                case "1":
                    return "unidad";
                case "2":
                    return "kg";
                case "3":
                    return "mt";
                case "4":
                    return "lt";
                case "5":
                    return "m2";
                case "6":
                    return "mtl";
                case "7":
                    return "gr";
                case "8":
                    return "juego";
                default:
                    return "";
            }

        };
        const formatCurrency = (value) => {
            if(value)
				return 'USD ' + value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
        };
        const openNew = () => {
            product.value = {};
            submitted.value = false;
            productDialog.value = true;
        };
        const hideDialog = () => {
            productDialog.value = false;
            submitted.value = false;
        };
        const displayErrors = (errors) => {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
        };
        const getStatusName = (index) => {
            let status = statuses.value.filter(val => val.value == index);
            if (status.length > 0) {
                return status[0].label;
            }
        };
        const saveProduct = () => {
            submitted.value = true;
            if (product.value.name.trim()) {
                if (product.value.id) {
                    CrudService.updateCRUD('insumos','insumo', product.value).then(
                        (data) => {
                            let validationErrors = [];
                            if (data.status == 400) { 

                              this.displayErrors(data.data);

                            } else {
                                /*
                                this.$toast.add({
                                    severity: "success",
                                    summary: "Éxito!",
                                    detail: "Actualizado correctamente.",
                                    life: 3000,
                                });
                                */
                                products.value[findIndexById(data.data.id)] = data.data;
                                product.value.inventoryStatus = data.status ? data.status : 'INSTOCK'; //product.value.inventoryStatus = product.value.inventoryStatus.value ? product.value.inventoryStatus.value : product.value.inventoryStatus;
                                toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
                                //$toast.add({severity:'success', summary: 'Éxito!', detail: 'Actualizado correctamente.', life: 3000});
                            }
                        });
                } else {
                    CrudService.createCRUD('insumos','insumo', product.value).then((data) => {                          
                        let validationErrors = [];
                        if (data.status == 400) {
                          this.displayErrors(data.data);                          
                        } else {
                            product.value.id = createId();
                            product.value.code = createId();
                            //product.value.image = 'product-placeholder.svg';
                            product.value.inventoryStatus = data.status ? data.status : 'INSTOCK';//product.value.inventoryStatus ? product.value.inventoryStatus.value : 'INSTOCK';
                            products.value.push(data.data);
                            //products.value[findIndexById(product.value.id)] = product.value;
                            //$toast.add({severity:'success', summary: 'Éxito!', detail: 'Creado correctamente.', life: 3000});                            
                            toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
                          }
                        }
                      );                    
                }
                productDialog.value = false;
                product.value = {};
            }

            
        };
        const addStock = (prod) => {
            product.value = {...prod};
            CrudService.updateCRUD('insumos','aumentar-stock', product.value).then((data) => {
                let validationErrors = [];
                if (data.status == 400) { 
                  this.displayErrors(data.data);
                } else {
                    products.value[findIndexById(data.data.id)] = data.data;
                    //product.value.inventoryStatus = data.status ? data.status : 'INSTOCK';
                    toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});                    
                }
            });
        };
        const editProduct = (prod) => {
            product.value = {...prod};
            productDialog.value = true;
        };
        const confirmDeleteProduct = (prod) => {
            product.value = prod;
            deleteProductDialog.value = true;
        };
        const deleteProduct = () => {
            // products.value = products.value.filter(val => val.id !== product.value.id);
            // deleteProductDialog.value = false;
            // product.value = {};
            // toast.add({severity:'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});

            CrudService.deleteCRUD('insumos', 'insumo', product.value.id).then(
                (data) => {
                    products.value.splice(findIndexById(data.data.id), 1);
                    deleteProductDialog.value = false;
                    toast.add({severity: "success",summary: "Éxito!",detail: "Eliminado correctamente.",life: 3000,});
                }
            );
        };
        const findIndexById = (id) => {
            let index = -1;
            for (let i = 0; i < products.value.length; i++) {
                if (products.value[i].id == id) {
                    index = i;
                    break;
                }
            }

            return index;
        };
        const createId = () => {
            let id = '';
            var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for ( var i = 0; i < 5; i++ ) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        }
        const exportCSV = () => {
            dt.value.exportCSV();
        };
        const confirmDeleteSelected = () => {
            deleteProductsDialog.value = true;
        };
        const deleteSelectedProducts = () => {
            products.value = products.value.filter(val => !selectedProducts.value.includes(val));
            deleteProductsDialog.value = false;
            selectedProducts.value = null;
            toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        };

        return { dt, products, productDialog, deleteProductDialog, deleteProductsDialog, product, 
            selectedProducts, filters, submitted, statuses, formatCurrency, openNew, hideDialog, saveProduct, editProduct, addStock, formatUnity, getStatusName,
            confirmDeleteProduct, deleteProduct, findIndexById, createId, exportCSV, confirmDeleteSelected, deleteSelectedProducts, estados}


    }
}
</script>

<style lang="scss" scoped>
.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}

.product-image {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
    width: 50px;
    margin: 0 auto 2rem auto;
    display: block;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
	::v-deep(.p-toolbar) {
		flex-wrap: wrap;
        
		.p-button {
            margin-bottom: 0.25rem;
        }
	}
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.product-badge.status-instock {
    background: #c8e6c9;
    color: #256029;
}

.product-badge.status-lowstock {
    background: #feedaf;
    color: #8a5340;
}

.product-badge.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
}
</style>